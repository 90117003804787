import { ReactComponent as NotFoundIcon } from '../assets/not-found.svg'

export default function NotFound() {
  return (
    <div className="h-100 d-flex flex-column align-items-center justify-content-center">
      <NotFoundIcon width={600}  height={400} />
      <div className={'not-found'}>404 Page not found</div>
    </div>
  )
}
