import { keepPreviousData, useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import axios from "axios"
import { useAuthCallPromise } from "use-eazy-auth"
import { PaginatedDJResponse, Study } from "../types"

export function useStudies(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<Study>>({
    queryKey: ["studies", params],
    queryFn: useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/v1/study/?${new URLSearchParams(params).toString()}`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    placeholderData: keepPreviousData
  });
}

export function useDeleteStudy() {
  const client = useQueryClient()
  return useMutation(
    {
      mutationFn: useAuthCallPromise(
        (token: string) => (id: number) =>
          axios.delete(`/api/v1/study/${id}/`, {
            headers: { Authorization: `Bearer ${token}` },
          })
      ),
      onSuccess() {
        client.invalidateQueries({
          queryKey: ["studies"]
        })
        client.invalidateQueries({
          queryKey: ["study"]
        })
      },
    }
  );
}

export function useDeleteMultiStudies() {
  const client = useQueryClient()
  return useMutation(
    {
      mutationFn: useAuthCallPromise(
        (token: string) => (ids: number[]) =>
          axios.post(
            `/api/v1/study/delete_many/`,
            { ids: ids },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
      ),
      onSuccess() {
        client.invalidateQueries({
          queryKey: ["studies"]
        })
        client.invalidateQueries({
          queryKey: ["study"]
        })
      },
    }
  );
}

export function useCreateStudy() {
  return useMutation({
    mutationFn: useAuthCallPromise(
      (token: string) => (file) =>
        axios
          .post(`/api/v1/study/upload/`, file, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data)
    )
  })
}
