import axios from "axios"
import { useQuery } from "@tanstack/react-query"

export function recoverPassword(email: string) {
  return axios
    .post("/api/v1/users/recover_password/", { email })
    .then((r) => r.data as void)
}

export function useCheckResetPasswordToken(token: string) {
  return useQuery({
    queryKey: ["checkResetPasswordToken"],
    queryFn: () =>
      axios
        .get(`/api/v1/users/check-reset-token/${token}/`)
        .then((r) => r.data as void),
    staleTime: 0,
  })
}

export function resetPassword(token: string, password: string) {
  return axios
    .post(`/api/v1/users/reset_password/`, { token, password })
    .then((r) => r.data as void)
}

export function activateAccount(token: string, password: string) {
  return axios
    .post(`/api/v1/users/activate_account/`, { token, password })
    .then((r) => r.data as void)
}
