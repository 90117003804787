import { ReactComponent as DeviceIcon } from "../../assets/device.svg"

export default function SmallDevicePlaceholder() {
  return (
    <div className="w-100 h-100 bg-secondary d-flex align-items-center flex-column justify-content-center">
      <div>
        <DeviceIcon />
      </div>
      <div className="text-center text-white pt-4 change-device-text">
        Please, change your device <br /> to explore the dashboard
      </div>
    </div>
  )
}
