import { FieldProps, getIn } from 'formik'


type InputFieldProps = FieldProps &
  React.InputHTMLAttributes<HTMLInputElement> & {
    containerClassName?: string
    required: boolean
    label?: string
  }

export const InputField = ({
  field,
  label,
  form: { touched, errors },
  containerClassName = 'mb-2',
  required = false,
  onChange,
  ...props
}: InputFieldProps) => {
  const touch = getIn(touched, field.name)
  const error = getIn(errors, field.name)
  return (
    <div className={`form-group ${containerClassName}`}>
      {label && (
        <label className='label-input'>
          {label}
          {required && <span className="text-danger">{' *'}</span>}
        </label>
      )}
      <input
        {...props}
        {...field}
        className={`w-100 input-field ${error && touch ? 'is-invalid' : ''}`}
        onChange={(e) => {
          field.onChange(e)
          onChange && onChange(e)
        }}
        value={field.value ?? ''}
      />
      {error && touch && <div className="invalid-feedback">{error}</div>}
    </div>
  )
}

type TextareaFieldProps = FieldProps &
  React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
    containerClassName?: string
    required: boolean
    label?: string
  }

export const TextareaField = ({
  field,
  label,
  form: { touched, errors },
  containerClassName = 'mb-2',
  required = false,
  ...props
}: TextareaFieldProps) => {
  const touch = getIn(touched, field.name)
  const error = getIn(errors, field.name)
  return (
    <div className={`form-group ${containerClassName}`}>
      {label && (
        <label>
          {label}
          {required && <span className="text-danger">{' *'}</span>}
        </label>
      )}
      <textarea
        {...props}
        {...field}
        className={`form-control ${error && touch ? 'is-invalid' : ''}`}
        value={field.value ?? ''}
      />
      {error && touch && <div className="invalid-feedback">{error}</div>}
    </div>
  )
}

type SelectFieldProps = FieldProps &
  React.SelectHTMLAttributes<HTMLSelectElement> & {
    containerClassName?: string
    required: boolean
    label?: string
  }

export const SelectField = ({
  field,
  label,
  form: { touched, errors },
  containerClassName = 'mb-2',
  required = false,
  children,
  onChange,
  ...props
}: SelectFieldProps) => {
  const touch = getIn(touched, field.name)
  const error = getIn(errors, field.name)
  return (
    <div className={`form-group ${containerClassName}`}>
      {label && (
        <label>
          {label}
          {required && <span className="text-danger">{' *'}</span>}
        </label>
      )}
      <select
        {...props}
        {...field}
        className={`form-select ${error && touch ? 'is-invalid' : ''}`}
        value={field.value ?? ''}
        onChange={(e) => {
          field.onChange(e)
          onChange && onChange(e)
        }}
      >
        {children}
      </select>
      {error && touch && <div className="invalid-feedback">{error}</div>}
    </div>
  )
}

type CheckBoxFieldProps = FieldProps &
  React.InputHTMLAttributes<HTMLInputElement> & {
    containerClassName?: string
    required: boolean
    label?: string
  }

export const CheckBoxField = ({
  field,
  label,
  form: { touched, errors },
  containerClassName = 'mb-2',
  required = false,
  ...props
}: CheckBoxFieldProps) => {
  const touch = getIn(touched, field.name)
  const error = getIn(errors, field.name)
  const { value, ...passField } = field
  return (
    <div className={`form-check ${containerClassName}`}>
      <input
        {...props}
        {...passField}
        type="checkbox"
        className={`form-check-input ${error && touch ? 'is-invalid' : ''}`}
        checked={field.value === true}
      />
      {label && (
        <label className="form-check-label" htmlFor={field.name}>
          {label}
          {required && <span className="text-danger">{' *'}</span>}
        </label>
      )}
      {error && touch && <div className="invalid-feedback">{error}</div>}
    </div>
  )
}
