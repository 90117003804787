import {
  keepPreviousData,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import axios from "axios"
import { useAuthCallPromise } from "use-eazy-auth"
import { Compound, PaginatedDJResponse } from "../types"

export function useCompounds(
  params: Record<string, any> = {},
  enabled: boolean
) {
  const queryClient = useQueryClient()
  if (!enabled) {
    queryClient.setQueryData(["compounds", params], [])
  }
  return useQuery<PaginatedDJResponse<Compound>>({
    queryKey: ["compounds", params],
    queryFn: useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/v1/compound/?${new URLSearchParams(params).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    placeholderData: keepPreviousData,
    enabled: enabled,
  })
}
