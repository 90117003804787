import { useMemo } from "react"
import { PAGE_SIZE } from "../consts"
import { ReactComponent as Next } from "../assets/icons/next.svg"
import { ReactComponent as Prev } from "../assets/icons/prev.svg"

function paginationRange(
  current: number,
  total: number,
  delta = 1,
  separator = "..."
) {
  if (total <= 1) return [1]

  const center = [current]
  for (let i = 1; i <= delta; i++) {
    center.push(current + i)
    center.unshift(current - i)
  }
  if (delta === 1 && current === 1) {
    center.push(3)
  }
  if (delta === 1 && current === total) {
    center.unshift(total - 2)
  }

  const filteredCenter: (number | string)[] = center.filter(
      (p) => p > 1 && p < total
    ),
    includeLeft = current === delta + 2 + 1,
    includeRight = current === total - delta - 2,
    includeLeftDots = current > delta + 2 + 1,
    includeRightDots = current < total - delta - 2

  if (includeLeft) filteredCenter.unshift(2)
  if (includeRight) filteredCenter.push(total - 1)

  if (includeLeftDots) filteredCenter.unshift(separator)
  if (includeRightDots) filteredCenter.push(separator)

  return [1, ...filteredCenter, total]
}

interface Props {
  count: number
  currentPage: number
  goToPage(page: number): void
  className?: string
}

const Paginator = ({
  count,
  currentPage,
  goToPage,
  className = "mt-2 d-flex justify-content-center align-items-center",
}: Props) => {
  const numPages = Math.ceil(count / PAGE_SIZE)
  const longPages = useMemo(
    () => paginationRange(currentPage, numPages, 10),
    [currentPage, numPages]
  )

  return (
    <nav aria-label="Paginator" className={className}>
      <ul className="pagination align-items-center my-2">
        <li
          className={`page-item pointer ${
            currentPage === null || currentPage === 1 ? "disabled" : ""
          }`}
        >
          <span
            className="me-3"
            aria-label="Previous"
            style={{
              opacity: currentPage === null || currentPage === 1 ? 0.5 : 1,
            }}
            onClick={() =>
              currentPage === null || currentPage === 1
                ? null
                : goToPage(currentPage - 1)
            }
          >
            <Prev />
          </span>
        </li>
        {longPages.map((page, i) =>
          typeof page === "string" ? (
            <li className="page-item" key={i}>
              <span className="page-link">{page}</span>
            </li>
          ) : (
            <li
              key={i}
              style={{ cursor: "pointer" }}
              className={`page-item ${currentPage === page ? "active" : ""} `}
            >
              <span onClick={() => goToPage(page)} className="page-link">
                {page}
              </span>
            </li>
          )
        )}
        <li
          className={`page-item pointer ${
            currentPage === null || !(currentPage < numPages) ? "disabled" : ""
          }`}
        >
          <span
            className="ms-2"
            aria-label="Next"
            style={{
              opacity:
                currentPage === null || !(currentPage < numPages) ? 0.5 : 1,
            }}
            onClick={() =>
              currentPage === null || !(currentPage < numPages)
                ? null
                : goToPage(currentPage + 1)
            }
          >
            <Next />
          </span>
        </li>
      </ul>
    </nav>
  )
}

export default Paginator
