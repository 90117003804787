import { Field, Formik } from "formik"
import { useState } from "react"
import { Button, Modal } from "react-bootstrap"
import * as Yup from "yup"
import { recoverPassword } from "../../hooks/password"
import { InputField, transformErrorForForm } from "../form"
import { ReactComponent as SuccessResetSend } from "../../assets/success-password.svg"
import styles from "./ForgotPasswordModal.module.css"

interface ForgotPasswordProps {
  show: boolean
  toggle: () => void | undefined
}

const initialValues = {
  email: "",
}

const RecoverPasswordSchema = Yup.object().shape({
  email: Yup.string().email().required().label("Email"),
})

export default function ForgotPasswordModal({
  show,
  toggle,
}: ForgotPasswordProps) {
  const [resetSended, setResetSended] = useState(false)

  return (
    <Modal size="sm" centered show={show} onHide={toggle} data-test="modal-reset_psw">
      <Formik
        validationSchema={RecoverPasswordSchema}
        onSubmit={(data, { setErrors }) =>
          recoverPassword(data.email).then(
            () => {
              setResetSended(true)
            },
            (error) => {
              setErrors(transformErrorForForm(error))
            }
          )
        }
        validateOnMount
        initialValues={initialValues}
      >
        {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Header className={"ModalHeader"}>
              <Modal.Title className={"ModalTitle"}>
                {resetSended ? "" : "Forgot password"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className={"ModalBodySmall"}>
              {!resetSended ? (
                <>
                  <div className={styles.TextForgot}>
                    Enter the email you used to create your account. We’ll send
                    you an email with instructions.
                  </div>
                  <div className="mt-4">
                    <Field
                      label="Email"
                      type="email"
                      name="email"
                      component={InputField}
                    />
                  </div>
                </>
              ) : (
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <SuccessResetSend />
                  <div className={styles.MessageSent}>
                    Message sent successfully!
                  </div>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer
              className={
                "ModalFooterWithoutBorder w-100 justify-content-center d-flex align-items-center"
              }
            >
              <Button
                variant="secondary"
                className="text-white"
                type="submit"
                disabled={isSubmitting}
                onClick={() => {
                  if (resetSended) {
                    setResetSended(false)
                    toggle()
                  }
                }}
              >
                {!resetSended ? "Send reset instructions" : "Ok"}
              </Button>
            </Modal.Footer>
          </form>
        )}
      </Formik>
    </Modal>
  )
}
