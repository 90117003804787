import { useCallback, useMemo, useState } from "react"
import Paginator from "../../components/Paginator"
import StickyTable from "../../components/StickyTable"
import { useCompounds } from "../../hooks/compounds"
import { useQsFilters } from "../../hooks/filters"
import { ReactComponent as UpIcon } from "../../assets/icons/up.svg"
import { ReactComponent as DownIcon } from "../../assets/icons/down.svg"
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg"
import { ReactComponent as CancelSearch } from "../../assets/icons/cancel-search.svg"
import { ReactComponent as NotFound } from "../../assets/not-found.svg"
import { ReactComponent as FiltersActive } from "../../assets/filters-active.svg"
import styles from "./Home.module.css"
import "react-datepicker/dist/react-datepicker.css"
import { Button, Dropdown, Form } from "react-bootstrap"
import { useOperators } from "../../hooks/operators"
import Select from "react-select"
import { useSolutions } from "../../hooks/solutions"
import Sorter, { SortControl } from "../../components/Sorter"
import classNames from "classnames"
import { ReactComponent as Matching } from "../../assets/matching.svg"
import { ReactComponent as Unmatching } from "../../assets/unmatching.svg"
import MatchUnmatchMasses from "../../components/DataManagement/MatchUnmatchMasses/MatchUnmatchMasses"
import { useTimespan } from "../../hooks/timespan"
import ReactDatePicker from "react-datepicker"
import { format } from "date-fns"
import { CompoundSearchParams } from "../../types"
// import DatePicker from "react-datepicker"

export const amountFormatter = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 10,
})

export const amountFormatterIt = new Intl.NumberFormat("it-IT", {
  maximumFractionDigits: 10,
})

export const LimitedChipsContainer = ({ children, ...props }: any) => {
  if (props.index > 0) return null

  const { length } = props.getValue()
  return length > 0 && props.data.value !== "" ? (
    <>
      <span className={styles.FilterNumberSelect}>{`${length}`}</span>
      <span className={styles.ItemsLabel}>Items</span>
    </>
  ) : (
    <span className={styles.ItemsLabelPlaceholder}>
      {props.selectProps.placeholder}
    </span>
  )
}

const filtersDefault = {
  page: 1,
  type_search: "identification_search",
  study_cluster: "ESI+",
  search: "",
  rt: "",
  rt_tollerance: "",
  masses: "all",
  mass_value: "",
  mass_tollerance: "",
  study__date__lte: "",
  study__date__gte: "",
  study__key: "",
  metadata: "operator",
  has_cas: false,
  has_note: false,
  ordering: "-id",
  study__solution__in: [],
  study__operator__in: [],
}

const optionsMetadata = [
  { value: "operator", label: "Operator" },
  { value: "solution", label: "Solution" },
  { value: "study", label: "Study" },
]

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get("page") ?? 1),
  search: params.get("search") ?? "",
  type_search: params.get("type_search") ?? "identification_search",
  study_cluster: params.get("study_cluster") ?? "ESI+",
  rt: params.get("rt") ?? "",
  rt_tollerance: params.get("rt_tollerance") ?? "",
  masses: params.get("masses") ?? "all",
  mass_value: params.get("mass_value") ?? "",
  mass_tollerance: params.get("mass_tollerance") ?? "",
  study__date__lte: params.get("study__date__lte") ?? "",
  study__date__gte: params.get("study__date__gte") ?? "",
  study__key: params.get("study__key") ?? "",
  metadata: params.get("metadata") ?? "operator",
  has_cas: (params.get("has_cas") ?? "false") === "true",
  has_note: (params.get("has_note") ?? "false") === "true",
  ordering: params.get("ordering") ?? "-id",
  study__operator__in: params.getAll("study__operator__in") ?? [],
  study__solution__in: params.getAll("study__solution__in") ?? [],
})

export function Home() {
  const { filters: qsParams, setFilters: setQsParams } =
    useQsFilters(initFilters)

  const [filters, _setFilters] = useState(qsParams)

  const isFiltersActive =
    Boolean(filters.mass_tollerance) ||
    Boolean(filters.mass_value) ||
    Boolean(filters.rt) ||
    Boolean(filters.rt_tollerance) ||
    Boolean(filters.study__date__gte) ||
    Boolean(filters.study__date__lte) ||
    Boolean(filters.metadata !== "operator") ||
    Boolean(filters.study__key) ||
    Boolean(filters.study_cluster !== "ESI+") ||
    Boolean(filters.study__operator__in.length > 0) ||
    Boolean(filters.study__solution__in.length > 0) ||
    Boolean(filters.has_cas === true) ||
    Boolean(filters.has_note === true)

  const [blockFilters, setOpenBlockFilters] = useState(!isFiltersActive)
  const [enableCompounds, setCompoundsEnabled] = useState(isFiltersActive)

  const setFilters = useCallback((updates: any) => {
    _setFilters((v) => ({ ...v, ...updates }))
  }, [])

  const apiFilters = useMemo(() => {
    const filters: Partial<CompoundSearchParams> = {
      page: qsParams.page,
      study_cluster: qsParams.study_cluster,
      study__operator__in: qsParams.study__operator__in,
      study__solution__in: qsParams.study__solution__in,
      study__date__gte: qsParams.study__date__gte,
      study__date__lte: qsParams.study__date__lte,
      study__key: qsParams.study__key,
      has_cas: qsParams.has_cas,
      has_note: qsParams.has_note,
      metadata: qsParams.metadata,
      ordering: qsParams.ordering,
    }
    if (qsParams.rt) {
      const rtBase = parseFloat(qsParams.rt)
      const rtTolerance = parseFloat(qsParams.rt_tollerance || "0")
      filters.rt__gte = rtBase - rtTolerance
      filters.rt__lte = rtBase + rtTolerance
    }
    if (qsParams.masses === "all") {
      if (qsParams.mass_value) {
        const massBase = parseFloat(qsParams.mass_value)
        if (qsParams.mass_tollerance) {
          const massTolerance = parseFloat(qsParams.mass_tollerance)
          filters.any_mass_min = massBase - massTolerance
          filters.any_mass_max = massBase + massTolerance
        } else {
          filters.any_mass_min = massBase
          filters.any_mass_max = massBase
        }
      }
    }
    if (qsParams.masses === "mz") {
      if (qsParams.mass_value) {
        const massBase = parseFloat(qsParams.mass_value)
        if (qsParams.mass_tollerance) {
          const massTolerance = parseFloat(qsParams.mass_tollerance)
          filters.mz__gte = massBase - massTolerance
          filters.mz__lte = massBase + massTolerance
        } else {
          filters.mz__gte = massBase
          filters.mz__lte = massBase
        }
      }
    }
    if (qsParams.search) {
      switch (qsParams.type_search) {
        case "identification_search":
        case "identification_lookup":
        case "identification_note_search":
        case "identification_note_lookup":
        case "identification_cas_lookup":
        case "identification_cas_search":
          filters[qsParams.type_search] = qsParams.search
          break
        default:
        // do nothing
      }
    }
    return filters
  }, [
    qsParams.has_cas,
    qsParams.has_note,
    qsParams.mass_tollerance,
    qsParams.mass_value,
    qsParams.masses,
    qsParams.metadata,
    qsParams.ordering,
    qsParams.page,
    qsParams.rt,
    qsParams.rt_tollerance,
    qsParams.search,
    qsParams.study__date__gte,
    qsParams.study__date__lte,
    qsParams.study__key,
    qsParams.study__operator__in,
    qsParams.study__solution__in,
    qsParams.study_cluster,
    qsParams.type_search,
  ])

  const isFiltersActiveApplied =
    Boolean(apiFilters.rt__lt) ||
    Boolean(apiFilters.rt__lte) ||
    Boolean(apiFilters.rt__gt) ||
    Boolean(apiFilters.rt__gte) ||
    Boolean(apiFilters.study_cluster !== "ESI+") ||
    Boolean(apiFilters.metadata !== "operator") ||
    Boolean(apiFilters.mz__lt) ||
    Boolean(apiFilters.mz__lte) ||
    Boolean(apiFilters.mz__gt) ||
    Boolean(apiFilters.mz__gte) ||
    Boolean(apiFilters.study__date__gte) ||
    Boolean(apiFilters.study__date__lte) ||
    Boolean(apiFilters.study__key) ||
    Boolean((apiFilters.study__operator__in?.length ?? 0) > 0) ||
    Boolean((apiFilters.study__solution__in?.length ?? 0) > 0) ||
    Boolean(apiFilters.has_cas === true) ||
    Boolean(apiFilters.has_note === true) ||
    Boolean(apiFilters.any_mass_min) ||
    Boolean(apiFilters.any_mass_max)

  const { data } = useCompounds(apiFilters, enableCompounds)
  const { data: operators } = useOperators()
  const { data: solutions } = useSolutions()
  const { data: timespan } = useTimespan()
  const defaultDate = useMemo(() => new Date(), [])

  const maxMasses = useMemo(() => {
    const masses = data?.results?.map((d) => d.masses?.length || 0) || []
    return Math.max(...masses) - 1
  }, [data])

  const operatorsForSelect = useMemo(() => {
    if (operators) {
      const op = operators.map((op) => ({
        label: op,
        value: op,
      }))
      return [{ value: "", label: "All operators" }].concat(op)
    }
  }, [operators])

  const solutionsForSelect = useMemo(() => {
    if (solutions) {
      const solu = solutions.map((sol) => ({
        label: sol,
        value: sol,
      }))
      return [{ value: "", label: "All solutions" }].concat(solu)
    }
  }, [solutions])

  const dateLte = useMemo(() => {
    if (filters.study__date__lte) {
      return new Date(filters.study__date__lte)
    } else {
      return new Date(timespan?.max_date ?? defaultDate)
    }
  }, [defaultDate, filters.study__date__lte, timespan?.max_date])

  const dateGte = useMemo(() => {
    if (filters.study__date__gte) {
      return new Date(filters.study__date__gte)
    } else {
      return new Date(timespan?.min_date ?? defaultDate)
    }
  }, [defaultDate, filters.study__date__gte, timespan?.min_date])

  const isDateFilterActive = useMemo(() => {
    return (
      (filters.study__date__gte &&
        filters.study__date__gte !== timespan?.min_date) ||
      (filters.study__date__lte &&
        filters.study__date__lte !== timespan?.max_date)
    )
  }, [filters, timespan])

  return (
    <div className="w-100 h-100 mb-3" data-test="page-home">
      <div className={styles.Home}>
        <div className="w-100 d-flex flex-column align-items-center position-relative">
          <div className={styles.BoxSearch}>
            <div className="w-100 d-flex">
              <div className="me-2">
                <Dropdown className={styles.NavLink}>
                  <Dropdown.Toggle
                    className={styles.ActiveSearch}
                    variant="transparent"
                    id="dropdown-basic"
                    data-test="dropdown-identification_code"
                  >
                    {filters.type_search === "identification_search" ||
                    filters.type_search === "identification_lookup"
                      ? "Identification Code"
                      : filters.type_search === "identification_note_search" ||
                        filters.type_search === "identification_note_lookup"
                      ? "Id. code and Note"
                      : "CAS"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className={styles.DropdownMenu}>
                    <Dropdown.Item
                      className={classNames(`${styles.DropdownItem} mb-2`, {
                        "text-decoration-underline":
                          filters.type_search === "identification_search" ||
                          filters.type_search === "identification_lookup",
                      })}
                      onClick={() => {
                        setFilters({
                          type_search: "identification_search",
                          page: 1,
                        })
                      }}
                      data-test="dropdown-item"
                    >
                      Identification Code
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={classNames(`${styles.DropdownItem} mb-2`, {
                        "text-decoration-underline":
                          filters.type_search ===
                            "identification_note_search" ||
                          filters.type_search === "identification_note_lookup",
                      })}
                      onClick={() => {
                        setFilters({
                          type_search: "identification_note_search",
                          page: 1,
                        })
                      }}
                    >
                      Id. code and Note
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={classNames(styles.DropdownItem, {
                        "text-decoration-underline":
                          filters.type_search === "identification_cas_search" ||
                          filters.type_search === "identification_cas_lookup",
                      })}
                      onClick={() => {
                        setFilters({
                          type_search: "identification_cas_search",
                          page: 1,
                        })
                      }}
                    >
                      CAS
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="position-relative w-100">
                <input
                  placeholder="Search"
                  className={
                    filters.search === ""
                      ? styles.SearchInput
                      : styles.SearchInputActive
                  }
                  type="text"
                  value={filters.search}
                  onChange={(e) => setFilters({ search: e.target.value })}
                />
                {filters.search !== "" && (
                  <div
                    className={styles.CancelSearch}
                    onClick={() => {
                      setFilters({
                        search: "",
                      })
                      setQsParams({
                        ...filters,
                        search: "",
                      })
                    }}
                  >
                    <CancelSearch />
                  </div>
                )}
                <div
                  className={`${styles.BlockSearchButton} position-absolute d-flex justify-content-between`}
                >
                  <Form.Check
                    checked={
                      filters.type_search === "identification_lookup" ||
                      filters.type_search === "identification_note_lookup" ||
                      filters.type_search === "identification_cas_lookup"
                    }
                    type="switch"
                    className={styles.MatchingResults}
                    onChange={() => {
                      if (filters.type_search === "identification_search") {
                        setFilters({
                          type_search: "identification_lookup",
                        })
                      } else if (
                        filters.type_search === "identification_note_search"
                      ) {
                        setFilters({
                          type_search: "identification_note_lookup",
                        })
                      } else if (
                        filters.type_search === "identification_note_lookup"
                      ) {
                        setFilters({
                          type_search: "identification_note_search",
                        })
                      } else if (
                        filters.type_search === "identification_lookup"
                      ) {
                        setFilters({
                          type_search: "identification_search",
                        })
                      } else if (
                        filters.type_search === "identification_cas_search"
                      ) {
                        setFilters({
                          type_search: "identification_cas_lookup",
                        })
                      } else if (
                        filters.type_search === "identification_cas_lookup"
                      ) {
                        setFilters({
                          type_search: "identification_cas_search",
                        })
                      }
                    }}
                    label={"Matching results"}
                    data-test="switch-matchs_results"
                  />
                  <button
                    type="submit"
                    onClick={() => {
                      setQsParams({ ...filters, page: 1 })
                      setOpenBlockFilters(false)
                      setCompoundsEnabled(true)
                    }}
                    className={`${styles.SearchButton} btn btn-secondary text-white`}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className={styles.ButtonFiltersOpen}
            style={{ top: blockFilters ? 446 : 82 }}
            onClick={() => setOpenBlockFilters(!blockFilters)}
          >
            Filters
            {isFiltersActiveApplied ? (
              <FiltersActive className={styles.FiltersActive} />
            ) : (
              ""
            )}
            {blockFilters ? <UpIcon /> : <DownIcon />}
          </div>
          <div
            className={styles.BlockFilters}
            style={{
              opacity: !blockFilters ? "0" : "1",
              pointerEvents: !blockFilters ? "none" : "all",
            }}
          >
            <div
              className={styles.BlockFiltersContent}
              style={{ transform: !blockFilters ? "scaleY(0)" : "scaleY(1)" }}
            >
              <div
                style={{ height: 120 }}
                className="d-flex justify-content-between border-bottom border-secondary-opacity pb-5"
              >
                <div>
                  <div className={styles.LabelFilter}>
                    Choose a study cluster
                  </div>
                  <div className={styles.ContainerSwitch}>
                    <div
                      onClick={() =>
                        setFilters({ ...filters, study_cluster: "ESI+" })
                      }
                      className={
                        filters.study_cluster === "ESI+"
                          ? styles.ActiveItem
                          : styles.Item
                      }
                      data-test="switch-cluster-1"
                    >
                      ESI+
                    </div>
                    <div
                      onClick={() =>
                        setFilters({ ...filters, study_cluster: "ESI-" })
                      }
                      className={
                        filters.study_cluster === "ESI-"
                          ? styles.ActiveItem
                          : styles.Item
                      }
                      data-test="switch-cluster-2"
                    >
                      ESI-
                    </div>
                  </div>
                </div>
                <div className="me-4 ms-4 w-1-secondary"></div>
                <div>
                  <div className={styles.LabelFilter}>Filter by</div>
                  <div className="d-flex text-nowrap align-items-center mt-3">
                    <div className={styles.RT}>RT</div>
                    <div className="ms-2 d-flex">
                      <input
                        className={styles.InputRT}
                        title="RT"
                        placeholder="000,000"
                        type={"number"}
                        onChange={(e) =>
                          setFilters({ ...filters, rt: e.target.value })
                        }
                        name="rt"
                        value={filters.rt}
                        data-test="input-rt1"
                      />
                      <div className={styles.TolleranceContainer}>
                        <input
                          className={classNames(
                            `${styles.InputRtTollerance} ms-1`,
                            { "ps-4": filters.rt_tollerance }
                          )}
                          title="RT"
                          placeholder={filters.rt_tollerance ? "" : "± 0,000"}
                          type={"number"}
                          onChange={(e) =>
                            setFilters({
                              ...filters,
                              rt_tollerance: e.target.value,
                            })
                          }
                          name="rt_tollerance"
                          value={filters.rt_tollerance}
                          data-test="input-rt2"
                        />
                        {filters.rt_tollerance && (
                          <span className={styles.Unit}>± </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="me-4 ms-4 w-1-secondary"></div>
                <div className="mt-3">
                  <div className={styles.LabelFilter}></div>
                  <div className={styles.ContainerSwitch}>
                    <div
                      onClick={() => setFilters({ ...filters, masses: "all" })}
                      className={
                        filters.masses === "all"
                          ? styles.ActiveItem
                          : styles.Item
                      }
                      data-test="switch-masses-1"
                    >
                      All masses
                    </div>
                    <div
                      onClick={() => setFilters({ ...filters, masses: "mz" })}
                      className={
                        filters.masses === "mz"
                          ? styles.ActiveItem
                          : styles.Item
                      }
                      data-test="switch-masses-2"
                    >
                      m/z
                    </div>
                  </div>
                </div>
                <div className="ms-1 mt-3">
                  <div className="d-flex text-nowrap align-items-center mt-3">
                    <div className="d-flex">
                      <input
                        className={styles.InputRT}
                        title="Mass"
                        type={"number"}
                        onChange={(e) =>
                          setFilters({
                            ...filters,
                            mass_value: e.target.value,
                          })
                        }
                        placeholder="000,000"
                        name="mass_value"
                        value={filters.mass_value}
                        data-test="input-mass1"
                      />
                      <div className={styles.TolleranceContainer}>
                        <input
                          className={classNames(
                            `${styles.InputRtTollerance} ms-1`,
                            { "ps-4": filters.mass_tollerance }
                          )}
                          title="Mass tollerance"
                          placeholder={filters.mass_tollerance ? "" : "± 0,000"}
                          type={"number"}
                          onChange={(e) =>
                            setFilters({
                              ...filters,
                              mass_tollerance: e.target.value,
                            })
                          }
                          name="mass_tollerance"
                          value={filters.mass_tollerance}
                          data-test="input-mass2"
                        />
                        {filters.mass_tollerance && (
                          <span className={styles.Unit}>± </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="me-4 ms-4 w-1-secondary"></div>
                <div>
                  <div className="d-flex justify-content-between">
                    <div className={styles.LabelFilter}>
                      Choose a time range
                    </div>
                    <div
                      onClick={() => {
                        if (isDateFilterActive) {
                          setFilters({
                            ...filters,
                            study__date__gte: "",
                            study__date__lte: "",
                          })
                        }
                      }}
                      className={
                        isDateFilterActive
                          ? styles.ResetDates
                          : styles.ResetDatesDisabled
                      }
                    >
                      Reset
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center mt-3"
                    data-test="datepicker"
                  >
                    <div className={styles.RT}>From</div>
                    <ReactDatePicker
                      className={`${styles.InputRTDate} ms-1`}
                      onChange={(date) => {
                        const d = date ? format(date, "yyyy-MM-dd") : ""
                        setFilters({
                          ...filters,
                          study__date__gte: String(d) || timespan?.min_date,
                        })
                      }}
                      showYearDropdown
                      dateFormat="MMM d, yyyy"
                      selected={dateGte}
                    />
                    <div className={`${styles.RT} ms-1`}>To</div>
                    <ReactDatePicker
                      className={`${styles.InputRTDate} ms-1`}
                      onChange={(date) => {
                        const d = date ? format(date, "yyyy-MM-dd") : ""
                        setFilters({
                          ...filters,
                          study__date__lte: String(d) || timespan?.max_date,
                        })
                      }}
                      showYearDropdown
                      dateFormat="MMM d, yyyy"
                      selected={dateLte}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{ height: 120 }}
                className="d-flex justify-content-between border-bottom border-secondary-opacity pt-4 pb-4"
              >
                <div>
                  <div className={styles.LabelFilter}>
                    Select one metadata to show
                  </div>
                  <div
                    className="mt-3 d-flex align-items-center"
                    data-test="select-metadata"
                  >
                    <Select
                      classNamePrefix={"metadata-filter"}
                      isSearchable={false}
                      value={
                        optionsMetadata.filter(
                          (d) => d.value === filters.metadata
                        )[0]
                      }
                      defaultValue={{ value: "operator", label: "Operator" }}
                      onChange={(e) => {
                        setFilters({ ...filters, metadata: e ? e.value : "" })
                      }}
                      options={optionsMetadata}
                    />
                  </div>
                </div>
                <div className="me-4 ms-4 w-1-secondary"></div>
                <div>
                  <div className={styles.LabelFilter}>
                    Select one or more items
                  </div>
                  <div
                    style={{ height: 38 }}
                    className="mt-3 d-flex align-items-center"
                    data-test="select-items-1"
                  >
                    <Select
                      classNamePrefix={"metadata-filter"}
                      isSearchable={false}
                      isClearable={false}
                      placeholder={"All operators"}
                      closeMenuOnSelect={false}
                      blurInputOnSelect={false}
                      isMulti
                      value={
                        filters.study__operator__in.length === 0
                          ? operatorsForSelect?.filter((d) => d.value === "")
                          : operatorsForSelect?.filter((d) =>
                              filters.study__operator__in.includes(d.value)
                            )
                      }
                      components={{
                        MultiValue: LimitedChipsContainer,
                      }}
                      hideSelectedOptions={false}
                      onChange={(values) => {
                        if (
                          filters.study__operator__in.length !== 0 &&
                          values.filter((d) => d.value === "").length > 0
                        ) {
                          setFilters({
                            ...filters,
                            study__operator__in: [],
                          })
                        } else {
                          setFilters({
                            ...filters,
                            study__operator__in: values
                              .map((c) => c.value)
                              .filter(Boolean),
                          })
                        }
                      }}
                      options={operatorsForSelect}
                    />
                    <div className="me-3 ms-3 w-1-secondary"></div>
                    <div
                      className="d-flex align-items-center"
                      data-test="select-items-2"
                    >
                      <Select
                        classNamePrefix={"metadata-filter"}
                        isSearchable={false}
                        isClearable={false}
                        closeMenuOnSelect={false}
                        placeholder={"All solutions"}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 100 }),
                        }}
                        isMulti
                        value={
                          filters.study__solution__in.length === 0
                            ? solutionsForSelect?.filter((d) => d.value === "")
                            : solutionsForSelect?.filter((d) =>
                                filters.study__solution__in.includes(d.value)
                              )
                        }
                        components={{
                          MultiValue: LimitedChipsContainer,
                        }}
                        hideSelectedOptions={false}
                        onChange={(values) => {
                          if (
                            filters.study__solution__in.length !== 0 &&
                            values.filter((d) => d.value === "").length > 0
                          ) {
                            setFilters({
                              ...filters,
                              study__solution__in: [],
                            })
                          } else {
                            setFilters({
                              ...filters,
                              study__solution__in: values
                                .map((c) => c.value)
                                .filter(Boolean),
                            })
                          }
                        }}
                        options={solutionsForSelect}
                      />
                    </div>
                  </div>
                </div>
                <div className="me-2 ms-2 w-1-secondary"></div>
                <div>
                  <div className="mt-4 pt-2 d-flex align-items-center">
                    <div className={`${styles.RT} me-2`}>Study</div>
                    <input
                      className={styles.InputStudyKey}
                      title="Study Key"
                      placeholder="Enter code"
                      type={"text"}
                      onChange={(e) =>
                        setFilters({ study__key: e.target.value })
                      }
                      name="study__key"
                      value={filters.study__key}
                    />
                  </div>
                </div>
                <div className="me-4 ms-4 w-1-secondary"></div>
                <div>
                  <div className={styles.LabelFilter}>
                    Show only results with:
                  </div>
                  <div className="mt-4 d-flex align-items-center">
                    <div
                      onClick={() => {
                        setFilters({
                          ...filters,
                          has_cas: !filters.has_cas,
                        })
                      }}
                      className="checkbox-container"
                      data-test="checkbox-cas"
                    >
                      {Boolean(filters.has_cas) ? <CheckIcon /> : ""}
                    </div>
                    <label className={`${styles.RT} ms-2`}>CAS</label>
                    <div
                      onClick={() => {
                        setFilters({
                          ...filters,
                          has_note: !filters.has_note,
                        })
                      }}
                      className="checkbox-container ms-3"
                      data-test="checkbox-notes"
                    >
                      {Boolean(filters.has_note) ? <CheckIcon /> : ""}
                    </div>
                    <label className={`${styles.RT} ms-2`}>Notes</label>
                  </div>
                </div>
              </div>
              <div className="w-100 d-flex justify-content-end pt-3">
                <Button
                  className="me-3"
                  variant="outline-secondary"
                  disabled={
                    !enableCompounds &&
                    !isFiltersActive &&
                    !isFiltersActiveApplied &&
                    filters.search === ""
                  }
                  onClick={() => {
                    setFilters(filtersDefault)
                    setQsParams({ ...filtersDefault })
                    setCompoundsEnabled(false)
                  }}
                >
                  Reset
                </Button>
                <Button
                  onClick={() => {
                    setQsParams({ ...filters, page: 1 })
                    setOpenBlockFilters(false)
                    setCompoundsEnabled(true)
                  }}
                  disabled={
                    !enableCompounds &&
                    !isFiltersActive &&
                    !isFiltersActiveApplied &&
                    filters.search === ""
                  }
                  variant="secondary"
                  className="text-white"
                  data-test="apply"
                >
                  Apply
                </Button>
              </div>
            </div>
          </div>
        </div>
        {data?.results && data!.results.length > 0 && (
          <div className={styles.FilterActiveMasses}>
            {qsParams.masses === "all" && qsParams.mass_value ? (
              <div>
                All masses value:{" "}
                <span className={styles.ValueFilterMasses}>
                  {amountFormatterIt.format(parseFloat(qsParams.mass_value))}
                </span>{" "}
                | Tolerance: ±{" "}
                <span className={styles.ValueFilterMasses}>
                  {amountFormatterIt.format(
                    qsParams.mass_tollerance
                      ? parseFloat(qsParams.mass_tollerance)
                      : 0.0
                  )}
                </span>
              </div>
            ) : (
              <div />
            )}
            <div>
              <span className={styles.NumberResults}>{data?.count}</span>{" "}
              results
            </div>
          </div>
        )}
        {qsParams.masses === "all" &&
          qsParams.mass_value &&
          data?.results &&
          data!.results.length > 0 && (
            <div className={styles.LegendMasses}>
              <div className="d-flex align-items-center">
                <Matching className="me-2" /> Matching
              </div>
              <div className="ms-3 d-flex align-items-center">
                <Unmatching className="me-2" /> Unmatching
              </div>
            </div>
          )}
        {data?.results && data!.results.length > 0 && enableCompounds && (
          <StickyTable
            className={`${styles.TableHome} table-fixed flex-1 mt-3`} data-test="table"
          >
            <thead>
              <Sorter
                value={filters.ordering}
                onSortChange={(ordering) => {
                  setFilters({
                    ...filters,
                    ordering,
                    page: 1,
                  })
                  setQsParams({
                    ...filters,
                    ordering,
                    page: 1,
                  })
                }}
              >
                <tr>
                  <th
                    style={{ width: "27%" }}
                    className="border-radius-left-20"
                  >
                    <div className="d-flex">
                      Identification <SortControl field="identification" />
                    </div>
                  </th>
                  <th style={{ width: "6%" }}>
                    <div className="d-flex">
                      RT <SortControl field="rt" />
                    </div>
                  </th>
                  <th style={{ width: "7%" }}>
                    <div className="d-flex">
                      m/z <SortControl field="mz" />
                    </div>
                  </th>
                  <th style={{ width: "32%" }}>Related m/z</th>
                  {qsParams.metadata === "operator" && (
                    <th style={{ width: "8%" }}>Operators</th>
                  )}
                  {qsParams.metadata === "solution" && (
                    <th style={{ width: "8%" }}>Solution</th>
                  )}
                  {qsParams.metadata === "study" && (
                    <th style={{ width: "8%" }}>Study</th>
                  )}
                  <th style={{ width: "10%" }}>
                    <div className="d-flex">
                      CAS <SortControl field="cas" />
                    </div>
                  </th>
                  <th
                    style={{ width: "11%" }}
                    className="border-radius-right-20"
                  >
                    <div className="d-flex">
                      Note <SortControl field="note" />
                    </div>
                  </th>
                </tr>
              </Sorter>
            </thead>
            <tbody>
              {data?.results?.map((compound) => (
                <tr key={compound.id}>
                  <td title={compound.identification} className="text-nowrap">
                    <div className={styles.Identification}>
                      {compound.identification}
                    </div>
                  </td>
                  <td>{amountFormatterIt.format(compound.rt)}</td>
                  <td>
                    <MatchUnmatchMasses
                      masses={"all"}
                      mz={Number(amountFormatter.format(compound.mz))}
                      mz_min={
                        Number(qsParams.mass_value) -
                        Number(qsParams.mass_tollerance)
                      }
                      mz_max={
                        Number(qsParams.mass_value) +
                        Number(qsParams.mass_tollerance)
                      }
                    />
                  </td>
                  <td className="text-nowrap">
                    <div className="d-flex flex-wrap">
                      {compound.masses?.map(
                        (mass, i) =>
                          i !== 0 && (
                            <div key={i}>
                              <MatchUnmatchMasses
                                masses={qsParams.masses}
                                mz={mass.mz}
                                mz_min={
                                  Number(qsParams.mass_value) -
                                  Number(qsParams.mass_tollerance)
                                }
                                mz_max={
                                  Number(qsParams.mass_value) +
                                  Number(qsParams.mass_tollerance)
                                }
                              />
                            </div>
                          )
                      )}
                      {(compound.masses?.length || 0) - 1 <= maxMasses &&
                        maxMasses !== -1 &&
                        [
                          ...Array(
                            maxMasses -
                              (compound.masses!.length !== 0
                                ? compound.masses!.length - 1
                                : 0)
                          ),
                        ].map((n, i) => (
                          <div className="placheholder-matching" key={i}>
                            {/* -*/}
                          </div>
                        ))}
                    </div>
                  </td>
                  {qsParams.metadata === "operator" && (
                    <td className="text-nowrap">{compound.study.operator}</td>
                  )}
                  {qsParams.metadata === "solution" && (
                    <td className="text-nowrap">{compound.study.solution}</td>
                  )}
                  {qsParams.metadata === "study" && (
                    <td className="text-nowrap">{compound.study.key}</td>
                  )}
                  <td title={compound.cas} className="text-nowrap">
                    <div className={styles.Cas}>
                      {compound.cas === "" ? "-" : compound.cas}
                    </div>
                  </td>
                  <td>
                    <div title={compound.note} className={styles.Notes}>
                      {compound.note === "" ? "-" : compound.note}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </StickyTable>
        )}
        {((data?.results && data?.results.length === 0) || !data?.results) && (
          <div className="h-100 d-flex flex-column align-items-center justify-content-center">
            <NotFound />
            <div className={styles.NotFound}>No results found</div>
            <div className={styles.InteractPanel}>
              Interact with the filter panel to discover the data
            </div>
          </div>
        )}
        {data?.results && data!.results.length > 0 && (
          <Paginator
            className="d-flex align-items-center justify-content-center"
            count={data!.count}
            currentPage={filters.page}
            goToPage={(page) => {
              setFilters({ ...filters, page })
              setQsParams({ ...filters, page })
            }}
          />
        )}
      </div>
    </div>
  )
}
