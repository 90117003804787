import { Button, Modal } from "react-bootstrap"
import { Study } from "../../../types"

interface ConfirmDeleteMultiStudiesProps {
  show: boolean
  toggle: () => void | undefined
  onConfirm: () => void
  numberStudies: number
}

export default function ConfirmDeleteMultiStudies({
  show,
  toggle,
  onConfirm,
  numberStudies,
}: ConfirmDeleteMultiStudiesProps) {
  return (
    <Modal size="sm" centered show={show} onHide={toggle} data-test="modal-delete_multi">
      <Modal.Header className={"ModalHeader"}>
        <Modal.Title className={"ModalTitle pt-4"}>Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body className={"ModalBodyNoPadding"}>
        <div className={"TextModal"}>
          You’re about to delete {numberStudies} studies. By deleting them,{" "}
          <br />
          they will no longer be available in the platform.
        </div>
      </Modal.Body>
      <Modal.Footer className={"ModalFooterWithoutBorder"}>
        <Button variant="outline-secondary" onClick={toggle}>
          Close
        </Button>
        <Button
          variant="secondary"
          className="text-white"
          type="submit"
          onClick={onConfirm}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
