import { keepPreviousData, useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import axios from "axios"
import { useEffect } from "react"
import ReconnectingWebSocket from "reconnectingwebsocket"
import { useAuthCallPromise } from "use-eazy-auth"
import pkg from "../../package.json"
import { Upload, UploadFeedMessage } from "../types"

const AUTO_REMOVE_COMPLETED_JOBS = true

export function useUploadJobs(params: Record<string, any> = {}) {
  const queryClient = useQueryClient()

  useEffect(() => {
    let socketUrl = "wss://" + window.location.hostname
    if (process.env.NODE_ENV === "development") {
      socketUrl = pkg.proxy.replace("http", "ws")
    }
    const ws = new ReconnectingWebSocket(socketUrl + "/ws/upload-feed/")

    ws.addEventListener("open", () => {
      console.log("SOCKET IS OPEN")
    })

    ws.addEventListener("message", (e) => {
      const msg = JSON.parse(e.data) as UploadFeedMessage
      console.log(msg)
      const upload = msg.upload
      queryClient.setQueryData<Upload[]>(["study-uploads"], (oldData) => {
        let newData = (oldData ?? []).map((item) =>
          item.id !== upload.id ? item : upload
        )
        if (!newData.some((item) => item.id === upload.id)) {
          newData.push(upload)
        }
        if (AUTO_REMOVE_COMPLETED_JOBS) {
          newData = newData.filter(
            (job) => job.status === "pending" || job.status === "error"
          )
        }
        return newData
      })
      if (msg.type === "success") {
        queryClient.invalidateQueries({
          queryKey: ["studies"]
        })
      }
    })

    return () => ws.close()
  }, [queryClient])

  return useQuery<Upload[]>({
    queryKey: ["study-uploads"],
    queryFn: useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/v1/study/upload/?status=pending`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    placeholderData: keepPreviousData
  });
}

export function useCancelUpload() {
  return useMutation(
    {
      mutationFn: useAuthCallPromise((token: string) => (id: number) => {
        return axios.post(
          `/api/v1/study/upload/${id}/cancel_if_possible/`,
          {},
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
      }),
      onSuccess() {
        // no need to invalidate queries here since we have websocket updates
      },
    }
  )
}

export function useCancelAllUploads() {
  return useMutation(
    {
      mutationFn: useAuthCallPromise((token: string) => () => {
        return axios.post(
          `/api/v1/study/upload/cancel_all/`,
          {},
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
      }),
      onSuccess() {
        // no need to invalidate queries here since we have websocket updates
      },
    }
  )
}
