import axios from 'axios'
import { useCallback } from 'react'
import { useAuthCallPromise } from 'use-eazy-auth'

export function useChangePassword() {
  const changePassword = useAuthCallPromise(
    useCallback(
      (token: string) => (data: { password: string, old_password: string }) =>
        axios
          .put('/api/v1/change-password/', data, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as void),
      []
    )
  )
  return changePassword
}
