import { useQsFilters } from "../../hooks/filters"
import styles from "./DataManagement.module.css"
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete.svg"
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg"
import { ReactComponent as MinusCheckIcon } from "../../assets/icons/minus-check.svg"
import { ReactComponent as NotFound } from "../../assets/not-found.svg"
import {
  useDeleteMultiStudies,
  useDeleteStudy,
  useStudies,
} from "../../hooks/studies"
import StickyTable from "../../components/StickyTable"
import Paginator from "../../components/Paginator"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import UploadFilesModal from "../../components/DataManagement/UploadFilesModal/UploadFilesModal"
import ConfirmDeleteStudyModal from "../../components/DataManagement/ConfirmDeleteStudyModal/ConfirmDeleteStudyModal"
import { useEffect, useState } from "react"
import classNames from "classnames"
import ConfirmDeleteMultiStudies from "../../components/DataManagement/ConfirmDeleteMultiStudies/ConfirmDeleteMultiStudies"

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get("page") ?? 1),
  search: params.get("search") ?? "",
})

export default function DataManagement() {
  const { filters, uiFilters, setFiltersDebounced, setFilters } =
    useQsFilters(initFilters)
  const { data } = useStudies(filters)
  const [modalUploadFiles, actionsModalUploadFiles] = useModalTrigger()
  const [modalDeleteStudy, actionsModalDeleteStudy] = useModalTrigger()
  const [modalMultiDeleteStudies, actionsModalMultiDeleteStudies] =
    useModalTrigger()
  const deleteStudy = useDeleteStudy()
  const [lastCreatedState, setLastCreatedState] = useState<string>()
  const [checkedStudies, setCheckedStudies] = useState<number[]>([])

  const deleteManyStudies = useDeleteMultiStudies()

  const lastCreatedAt = data?.results[0]?.created_at ?? undefined

  const handleSingleCheck = (id: number) => {
    if (checkedStudies.includes(Number(id))) {
      setCheckedStudies(
        checkedStudies.filter((checkedId) => checkedId !== Number(id))
      )
    } else {
      checkedStudies.push(Number(id))
      setCheckedStudies([...checkedStudies])
    }
  }

  useEffect(() => {
    if (
      lastCreatedAt !== undefined &&
      (lastCreatedState === undefined || lastCreatedState < lastCreatedAt)
    ) {
      setTimeout(() => {
        setLastCreatedState(lastCreatedAt)
      }, 5000)
    }
  }, [lastCreatedAt, lastCreatedState])

  return (
    <div className="w-100 h-100 mb-3">
      <div className={styles.DataManagement}>
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <h2 className={styles.Title}>Data management</h2>
            <div>
              <button
                onClick={() => actionsModalUploadFiles.open()}
                className="btn btn-secondary text-white"
                data-test="add"
              >
                Upload new data
              </button>
            </div>
          </div>
          <div className="mt-4 d-flex justify-content-between align-items-center w-100">
            <input
              placeholder="Search"
              className={styles.SearchInput}
              type="text"
              value={uiFilters.search}
              onChange={(e) => setFiltersDebounced({ search: e.target.value })}
            />
            <div className="d-flex align-items-center" style={{ height: 40 }}>
              {checkedStudies.length > 0 && (data?.results.length ?? 0) > 0 && (
                <div
                  onClick={() => {
                    if (checkedStudies.length > 0) {
                      actionsModalMultiDeleteStudies.open()
                    }
                  }}
                  className={styles.CheckedItemsForDelete}
                >
                  <div>{checkedStudies.length} rows selected</div>
                  <div className="icon-hover">
                    <DeleteIcon
                      onClick={() => {
                        if (checkedStudies.length > 0) {
                          actionsModalMultiDeleteStudies.open()
                        }
                      }}
                      className="pointer"
                      data-test="delete-multi"
                    />
                  </div>
                </div>
              )}
              <div>
                <span className={styles.NumberResults}>
                  {data?.count}/{data?.full_count}
                </span>
              </div>
            </div>
          </div>
        </div>
        {(data?.results.length ?? 0) > 0 ? (
          <StickyTable className="flex-1 mt-5" data-test="table">
            <thead>
              <tr>
                <th className="border-radius-left-20">
                  <div
                    onClick={() => {
                      if (checkedStudies.length > 0) {
                        setCheckedStudies([])
                      } else {
                        data!.results.map((study) => {
                          if (!checkedStudies.includes(Number(study.id))) {
                            handleSingleCheck(Number(study.id))
                          }
                        })
                      }
                    }}
                    className="ms-3 checkbox-container-white"
                  >
                    {checkedStudies.length === (data!.results.length ?? 0) ? (
                      <CheckIcon stroke="white" />
                    ) : checkedStudies.length > 0 ? (
                      <MinusCheckIcon />
                    ) : (
                      ""
                    )}
                  </div>
                </th>
                <th>Study</th>
                <th>Solution</th>
                <th>Timepoint</th>
                <th>Operator</th>
                <th>Creation date</th>
                <th>Note</th>
                <th className="border-radius-right-20"></th>
              </tr>
            </thead>
            <tbody>
              {data!.results.map((study) => (
                <tr
                  key={study.id}
                  className={classNames(styles.StudyRow, {
                    [styles.HighlightRow]:
                      study.created_at !== undefined &&
                      lastCreatedState !== undefined &&
                      study.created_at > lastCreatedState,
                  })}
                >
                  <td>
                    <div
                      onClick={() => {
                        handleSingleCheck(Number(study.id))
                      }}
                      className="ms-3 checkbox-container-secondary"
                      data-test="checkbox"
                    >
                      {checkedStudies.includes(Number(study.id)) ? (
                        <CheckIcon stroke="var(--secondary)" />
                      ) : (
                        ""
                      )}
                    </div>
                  </td>
                  <td>{study.key}</td>
                  <td>{study.solution}</td>
                  <td>{study.timepoint}</td>
                  <td>{study.operator}</td>
                  <td>{study.date}</td>
                  <td>{study.note}</td>
                  <td>
                    <div className="icon-hover">
                      <DeleteIcon
                        opacity={checkedStudies.length > 0 ? 0.3 : 1}
                        onClick={() => {
                          if (checkedStudies.length === 0) {
                            actionsModalDeleteStudy.open(study)
                          }
                        }}
                        className={
                          checkedStudies.length === 0 ? "pointer" : "initial"
                        }
                        data-test="delete-single"
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </StickyTable>
        ) : (
          <div className="d-flex pt-5 flex-column align-items-center justify-content-center">
            <NotFound />
            <div className={styles.NotFound}>No results found</div>
            <div className={styles.InteractPanel}>
              Interact with the filter panel to discover the data
            </div>
          </div>
        )}
        {(data?.results.length ?? 0) > 0 && (
          <Paginator
            count={data!.count}
            currentPage={uiFilters.page}
            goToPage={(page) => setFilters({ page })}
          />
        )}
      </div>
      {modalUploadFiles.isOpen && (
        <UploadFilesModal
          show={modalUploadFiles.isOpen}
          toggle={actionsModalUploadFiles.toggle}
        />
      )}
      <ConfirmDeleteStudyModal
        show={modalDeleteStudy.isOpen}
        toggle={actionsModalDeleteStudy.toggle}
        onConfirm={async () => {
          await deleteStudy.mutateAsync(modalDeleteStudy.value!.id)
          actionsModalDeleteStudy.close()
        }}
        study={modalDeleteStudy.value}
      />
      <ConfirmDeleteMultiStudies
        show={modalMultiDeleteStudies.isOpen}
        numberStudies={checkedStudies.length}
        toggle={actionsModalMultiDeleteStudies.toggle}
        onConfirm={async () => {
          await deleteManyStudies.mutateAsync(checkedStudies)
          actionsModalMultiDeleteStudies.close()
        }}
      />
    </div>
  )
}
