import { Field, Formik } from "formik"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import { useAuthUser } from "use-eazy-auth"
import * as Yup from "yup"
import ChangePasswordModal from "../../components/ChangePasswordModal/ChangePasswordModal"
import TopBar from "../../components/TopBar/TopBar"
import { InputField, transformErrorForForm } from "../../components/form"
import { useChangePassword } from "../../hooks/account"
import { AccountUser } from "../../types"
import styles from "./Profile.module.css"

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password è un campo obbligatorio")
    .min(8, "La password deve essere lunga almeno 8 caratteri."),
  repeatPassword: Yup.string()
    .required("Conferma Password è un campo obbligatorio")
    .oneOf([Yup.ref("password")], "Please, make sure your passwords match."),
})

export function Profile() {
  const { user } = useAuthUser<AccountUser>()
  const [modalChangePassword, actionsChangePassword] = useModalTrigger()

  const initialValues = {
    old_password: "",
    password: "",
    repeatPassword: "",
    email: user?.email,
  }

  const changePassword = useChangePassword()
  return (
    <div className="h-100 w-100">
      <TopBar showMenu={true} />
      <div className={styles.ResetPassword} data-test="page-change_psw">
        <div className={`${styles.LeftBox} bg-secondary h-100`}>
          <div className="d-flex h-100 w-100 flex-column justify-content-center">
            <h2 className="app-title">ELSE</h2>
            <div className="app-subtitle">Extractables LC/MS Search Engine</div>
          </div>
        </div>
        <div className={`${styles.RightBox} bg-primary`}>
          <Formik
            initialTouched={{ password: true, repeatPassword: true }}
            onSubmit={(data, { setErrors, setTouched }) =>
              changePassword({
                password: data.password,
                old_password: data.old_password,
              }).then(
                () => {
                  actionsChangePassword.open()
                },
                (error) => {
                  setErrors(transformErrorForForm(error))
                }
              )
            }
            validationSchema={ResetPasswordSchema}
            initialValues={initialValues}
          >
            {({ handleSubmit, errors, isSubmitting, isValid }) => (
              <form
                onSubmit={handleSubmit}
                className="w-100 h-100 d-flex flex-column justify-content-center p-5"
              >
                <div>
                  <div className={styles.Title}>Change password</div>
                </div>
                <div className="mt-5">
                  <Field
                    label="E-mail"
                    type="text"
                    disabled
                    className={styles.InputDisabled}
                    name="email"
                    component={InputField}
                  />
                  <Field
                    label="Current password"
                    type="password"
                    placeholder={"Enter password"}
                    className={styles.Input}
                    name="old_password"
                    component={InputField}
                  />
                  <Field
                    label="New password"
                    type="password"
                    placeholder={"Enter password"}
                    className={styles.Input}
                    name="password"
                    component={InputField}
                  />
                  <Field
                    label="Repeat new password"
                    type="password"
                    placeholder={"Enter password"}
                    className={styles.Input}
                    name="repeatPassword"
                    component={InputField}
                  />
                </div>
                <div className="d-flex justify-content-end mt-3">
                  <button
                    type="submit"
                    disabled={isSubmitting || !isValid}
                    className="btn btn-secondary text-white"
                  >
                    Confirm
                  </button>
                </div>
                {(errors as any).__noFieldsServerError && (
                  <div className="alert alert-transparent mt-2">
                    We’re sorry. It seems like the password you’ve entered is
                    incorrect. To protect your account, choose a new password.
                  </div>
                )}
              </form>
            )}
          </Formik>
        </div>
      </div>
      <ChangePasswordModal
        show={modalChangePassword.isOpen}
        toggle={actionsChangePassword.toggle}
      />
    </div>
  )
}
