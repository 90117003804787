import { useState } from "react"
import * as Yup from "yup"
import { resetPassword } from "../../hooks/password"
import TopBar from "../../components/TopBar/TopBar"
import styles from "./ResetPassword.module.css"
import { Field, Formik } from "formik"
import { Link, useParams } from "react-router-dom"
import { InputField, transformErrorForForm } from "../../components/form"

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password è un campo obbligatorio")
    .min(8, "La password deve essere lunga almeno 8 caratteri."),
  repeatPassword: Yup.string()
    .required("Conferma Password è un campo obbligatorio")
    .oneOf([Yup.ref("password")], "Le password non corrispondono."),
})

const initialValues = {
  password: "",
  repeatPassword: "",
}

export function ResetPassword() {
  const { token } = useParams()
  const [resetOk, setResetOk] = useState(false)

  return (
    <div className="h-100 w-100">
      <TopBar showMenu={false} />
      <div className={styles.ResetPassword}>
        <div className={`${styles.LeftBox} bg-secondary h-100`}>
          <div className="d-flex h-100 w-100 flex-column justify-content-center">
            <h2 className="app-title">ELSE</h2>
            <div className="app-subtitle">Extractables LC/MS Search Engine</div>
          </div>
        </div>
        <div className={`${styles.RightBox} bg-primary`}>
          {resetOk ? (
            <div className="w-100 h-100 d-flex align-items-center">
              <div className="alert text-center w-100 alert-success me-2 ms-2">
                Reset password go successfully.
                <br />
                Go to <Link to="/login">Login</Link>.
              </div>
            </div>
          ) : (
            <Formik
              initialTouched={{ password: true, repeatPassword: true }}
              onSubmit={(data, { setErrors }) =>
                resetPassword(token!, data.password).then(
                  () => {
                    setResetOk(true)
                  },
                  (error) => {
                    setErrors(transformErrorForForm(error))
                  }
                )
              }
              validationSchema={ResetPasswordSchema}
              initialValues={initialValues}
            >
              {({ handleSubmit, errors, isSubmitting, isValid }) => (
                <form
                  onSubmit={handleSubmit}
                  className="w-100 h-100 d-flex flex-column justify-content-center p-5"
                >
                  <div>
                    <div className={styles.Title}>Recover password</div>
                  </div>
                  <Field
                    label="Password"
                    type="password"
                    placeholder={"Insert the new password"}
                    className={styles.Input}
                    name="password"
                    component={InputField}
                  />
                  <Field
                    label="Conferma Password"
                    type="password"
                    placeholder={"Repeat the new password"}
                    className={styles.Input}
                    name="repeatPassword"
                    component={InputField}
                  />
                  <div className="d-flex justify-content-end mt-3">
                    <button
                      type="submit"
                      disabled={isSubmitting || !isValid}
                      className="btn btn-secondary text-white"
                    >
                      Reset Password
                    </button>
                  </div>
                  {(errors as any).__noFieldsServerError && (
                    <div className="alert alert-transparent mt-2">
                      Impossibile eseguire la proedura di reset password,
                      riprova più tardi.
                    </div>
                  )}
                </form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  )
}
