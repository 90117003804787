import { useEffect, useState } from "react"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import { useAuthActions, useAuthState } from "use-eazy-auth"
import TopBar from "../../components/TopBar/TopBar"
import styles from "./Login.module.css"
import ForgotPasswordModal from "../../components/ForgotPasswordModal/ForgotPasswordModal"

export function Login() {
  const { loginLoading, loginError } = useAuthState()
  const { login, clearLoginError } = useAuthActions()
  const [modalForgotPassword, actionsModalForgotPassword] = useModalTrigger()

  // Clear login error when Login component unmount
  useEffect(() => () => clearLoginError(), [clearLoginError])

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  return (
    <div className="h-100 w-100" data-test="login">
      <TopBar showMenu={false} />
      <div className={styles.Login}>
        <div className={`${styles.LeftBox} bg-secondary h-100`}>
          <div className="d-flex h-100 w-100 flex-column justify-content-center">
            <h2 className="app-title">ELSE</h2>
            <div className="app-subtitle">Extractables LC/MS Search Engine</div>
          </div>
        </div>
        <div className={`${styles.RightBox} bg-primary position-relative`}>
          <form
            autoComplete="off"
            className="w-100 h-100 d-flex flex-column justify-content-center p-5"
            onSubmit={(e) => {
              e.preventDefault()
              if (email !== "" && password !== "") {
                login({ email, password })
              }
            }}
          >
            <div>
              <div className={styles.Title}>Sign in</div>
            </div>
            <div className="pt-3 mb-3">
              <label className={styles.LabelLogin}>Email</label>
              <input
                placeholder="Enter e-mail"
                name="email"
                className={styles.InputLogin}
                type="email"
                autoComplete="false"
                autoCorrect="false"
                value={email}
                onChange={(e) => {
                  clearLoginError()
                  setEmail(e.target.value)
                }}
                data-test="email"
              />
            </div>
            <div className="mb-3">
              <label className={styles.LabelLogin}>Password</label>
              <input
                placeholder="Enter password"
                name="password"
                autoComplete="false"
                autoCorrect="false"
                className={styles.InputLogin}
                type="password"
                value={password}
                onChange={(e) => {
                  clearLoginError()
                  setPassword(e.target.value)
                }}
                data-test="password"
              />
            </div>
            {loginError && (
              <div className="alert alert-transparent mt-2">
                We’re sorry. The email or password you entered did not match our
                records. Please double-check and try again.
              </div>
            )}
            <div className="d-flex align-items-center justify-content-between mt-3">
              <div
                onClick={() => actionsModalForgotPassword.open()}
                className={styles.RecoverPassword}
                data-test="reset"
              >
                Forgot password?
              </div>
              <button
                className="btn btn-secondary text-white"
                disabled={loginLoading}
                data-test="login"
              >
                Confirm
              </button>
            </div>
          </form>
        </div>
      </div>
      {modalForgotPassword.isOpen && (
        <ForgotPasswordModal
          show={modalForgotPassword.isOpen}
          toggle={actionsModalForgotPassword.toggle}
        />
      )}
    </div>
  )
}
