import { Button, Modal } from "react-bootstrap"
import { Study } from "../../../types"

interface ConfirmDeleteStudyModalProps {
  show: boolean
  toggle: () => void | undefined
  onConfirm: () => void
  study?: Study
}

export default function ConfirmDeleteStudyModal({
  show,
  toggle,
  onConfirm,
  study,
}: ConfirmDeleteStudyModalProps) {
  return (
    <Modal size="sm" centered show={show} onHide={toggle} data-test="modal-delete_single">
      <Modal.Header className={"ModalHeader"}>
        <Modal.Title className={"ModalTitle"}>Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body className={"ModalBodyNoPadding"}>
        <div className={"TextModal"}>
          You’re about to delete study {study?.key}. By deleting it, <br />it will no
          longer be available in the platform.
        </div>
      </Modal.Body>
      <Modal.Footer className={"ModalFooterWithoutBorder"}>
        <Button variant="outline-secondary" onClick={toggle}>
          Close
        </Button>
        <Button
          variant="secondary"
          className="text-white"
          type="submit"
          onClick={onConfirm}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
