import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import axios from "axios"
import { useAuthCallPromise } from "use-eazy-auth"
import { AccountUser, BaseAccountUser, PaginatedDJResponse } from "../types"
import { QueryOnlyOptions } from "./utils"

export function useAccountUsers(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<AccountUser>>({
    queryKey: ["users", params],
    queryFn: useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/v1/users/?${new URLSearchParams(params).toString()}`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    placeholderData: keepPreviousData,
  })
}

export function useAccountUser(id: number, queryOptions?: QueryOnlyOptions) {
  return useQuery({
    queryKey: ["user", id],
    queryFn: useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/v1/users/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    ...queryOptions 
  })
}

export function useDeleteAccountUser() {
  const client = useQueryClient()
  return useMutation({
    mutationFn: useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/v1/users/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    onSuccess() {
      client.invalidateQueries({
        queryKey: ["users"],
      })
      client.invalidateQueries({
        queryKey: ["user"],
      })
    },
  })
}

export function useCreateAccountUser() {
  const client = useQueryClient()
  return useMutation<AccountUser, Error, BaseAccountUser>({
    mutationFn: useAuthCallPromise(
      (token: string) => (user: BaseAccountUser) =>
        axios
          .post(`/api/v1/users/`, user, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as AccountUser)
    ),
    onSuccess(savedUser) {
      client.invalidateQueries({
        queryKey: ["users"],
      })
      client.setQueryData(["user", savedUser.id], savedUser)
    },
  })
}

export function useUpdateAccountUser() {
  const client = useQueryClient()
  return useMutation<AccountUser, Error, BaseAccountUser>({
    mutationFn: useAuthCallPromise(
      (token: string) => (user: BaseAccountUser) =>
        axios
          .put(`/api/v1/users/${user.id}/`, user, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as AccountUser)
    ),
    onSuccess(savedUser) {
      client.invalidateQueries({
        queryKey: ["users"],
      })
      client.setQueryData(["user", savedUser.id], savedUser)
    },
  })
}

export function useReSendUserActivationLink() {
  return useMutation({
    mutationFn: useAuthCallPromise(
      (token: string) => (id: number) =>
        axios
          .post(
            `/api/v1/users/${id}/resend_activation_email/`,
            {},
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((r) => r.data as void)
    ),
  })
}
