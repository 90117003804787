import styles from "./TopBar.module.css"
import { ReactComponent as Logo } from "../../assets/logo.svg"
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom"
import { useAuthActions, useAuthUser } from "use-eazy-auth"
import { AccountUser } from "../../types"
import { Dropdown } from "react-bootstrap"

interface TopBarProps {
  showMenu: boolean
}

export default function TopBar({ showMenu }: TopBarProps) {
  const { user } = useAuthUser<AccountUser>()
  const { logout } = useAuthActions()
  const navigate = useNavigate()
  const location = useLocation()
  return (
    <div className={styles.TopBar}>
      <div className={styles.BlockLogo}>
        <Logo />
        {showMenu && (
          <>
            <span className={styles.BorderLogo}></span>
            <Link
              className={
                location.pathname === "/"
                  ? styles.LabelDashboardActive
                  : styles.LabelDashboard
              }
              to="/"
            >
              <span>Dashboard</span>
            </Link>
          </>
        )}
      </div>
      {showMenu && (
        <div className="d-flex align-items-center">
          {user?.permission_accounts && (
            <NavLink
              className={({ isActive }) =>
                isActive ? styles.NavLinkActive : styles.NavLink
              }
              to="/account-management"
              data-test="account-management"
            >
              Account management
            </NavLink>
          )}
          {user?.permission_editor && (
            <NavLink
              className={({ isActive }) =>
                isActive ? styles.NavLinkActive : styles.NavLink
              }
              to="/data-management"
              data-test="data-management"
            >
              Data management
            </NavLink>
          )}
          <Dropdown className={styles.NavLink} data-test="dropdown-user">
            <Dropdown.Toggle variant="white" id="dropdown-basic">
              {user?.full_name}
            </Dropdown.Toggle>
            <Dropdown.Menu className={styles.DropdownMenu}>
              <Dropdown.Item
                onClick={() => navigate("/profile")}
                className={`${
                  location.pathname === "/profile"
                    ? styles.DropdownItemActive
                    : styles.DropdownItem
                } mb-2`}
                data-test="dropdown-change_psw"
              >
                Change password
              </Dropdown.Item>
              <Dropdown.Item
                className={styles.DropdownItem}
                onClick={() => logout()}
              >
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
    </div>
  )
}
