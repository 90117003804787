import { Modal } from "react-bootstrap"
import { Link } from "react-router-dom"
import { ReactComponent as SuccessResetSend } from "../../assets/success-password.svg"
import styles from "./ChangePasswordModal.module.css"

interface ChangePasswordModalProps {
  show: boolean
  toggle: () => void | undefined
}

export default function ChangePasswordModal({
  show,
  toggle,
}: ChangePasswordModalProps) {
  return (
    <Modal size="sm" centered show={show} onHide={toggle}>
      <Modal.Header className={"ModalHeader"}>
        <Modal.Title className={"ModalTitle pt-4"}></Modal.Title>
      </Modal.Header>
      <Modal.Body className={"ModalBodyNoPadding"}>
        <div className="d-flex justify-content-center align-items-center flex-column">
          <SuccessResetSend />
          <div className={styles.ActivationOk}>
            Password changed successfully!
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        className={
          "ModalFooterWithoutBorder w-100 justify-content-center d-flex align-items-center"
        }
      >
        <Link to="/" className="btn btn-secondary text-white">
          OK
        </Link>
      </Modal.Footer>
    </Modal>
  )
}
