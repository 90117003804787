import { keepPreviousData, useQuery } from "@tanstack/react-query"
import axios from "axios"
import { useAuthCallPromise } from "use-eazy-auth"

export function useSolutions(params: Record<string, any> = {}) {
  return useQuery<string[]>({
    queryKey: ["solutions", params],
    queryFn: useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/v1/enums/solutions/?${new URLSearchParams(
                params
              ).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    placeholderData: keepPreviousData,
  })
}
