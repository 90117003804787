import * as Yup from "yup"
import { activateAccount } from "../../hooks/password"
import TopBar from "../../components/TopBar/TopBar"
import styles from "./ActivateAccount.module.css"
import { Field, Formik } from "formik"
import { useParams } from "react-router-dom"
import { InputField, transformErrorForForm } from "../../components/form"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import ActivationAccountModal from "../../components/ActivationAccountModal/ActivationAccountModal"

const ActivateAccountSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password è un campo obbligatorio")
    .min(8, "La password deve essere lunga almeno 8 caratteri."),
  repeatPassword: Yup.string()
    .required("Conferma Password è un campo obbligatorio")
    .oneOf([Yup.ref("password")], "Le password non corrispondono."),
})

const initialValues = {
  password: "",
  repeatPassword: "",
}

export function ActivateAccount() {
  const { token } = useParams()
  const [modalAccountCreated, actionsModalAccountCreated] = useModalTrigger()
  return (
    <div className="h-100 w-100">
      <TopBar showMenu={false} />
      <div className={styles.ActivateAccount}>
        <div className={`${styles.LeftBox} bg-secondary h-100`}>
          <div className="d-flex h-100 w-100 flex-column justify-content-center">
            <h2 className="app-title">ELSE</h2>
            <div className="app-subtitle">Extractables LC/MS Search Engine</div>
          </div>
        </div>
        <div className={`${styles.RightBox} bg-primary`}>
          <Formik
            initialTouched={{ password: true, repeatPassword: true }}
            onSubmit={(data, { setErrors }) =>
              activateAccount(token!, data.password).then(
                () => {
                  actionsModalAccountCreated.open()
                },
                (error) => {
                  setErrors(transformErrorForForm(error))
                }
              )
            }
            validationSchema={ActivateAccountSchema}
            initialValues={initialValues}
          >
            {({ handleSubmit, errors, isSubmitting, isValid }) => (
              <form
                onSubmit={handleSubmit}
                className="w-100 h-100 d-flex flex-column justify-content-center p-5"
              >
                <div>
                  <div className={styles.Title}>Account creation</div>
                </div>
                <Field
                  label="Password"
                  type="password"
                  placeholder={"Enter password"}
                  className={styles.Input}
                  name="password"
                  component={InputField}
                />
                <Field
                  label="Conferma Password"
                  type="password"
                  placeholder={"Repeat the password"}
                  className={styles.Input}
                  name="repeatPassword"
                  component={InputField}
                />
                <div className="d-flex justify-content-end mt-3">
                  <button
                    type="submit"
                    disabled={isSubmitting || !isValid}
                    className="btn btn-secondary text-white"
                  >
                    Confirm
                  </button>
                </div>
                {(errors as any).__noFieldsServerError && (
                  <div className="alert alert-transparent mt-2">
                    Impossibile eseguire la proedura di attivazione, riprova più
                    tardi.
                  </div>
                )}
              </form>
            )}
          </Formik>
        </div>
      </div>
      <ActivationAccountModal
        show={modalAccountCreated.isOpen}
        toggle={actionsModalAccountCreated.toggle}
      />
    </div>
  )
}
