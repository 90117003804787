import { Button, Modal } from "react-bootstrap"
import { useDropzone } from "react-dropzone"
import { ReactComponent as UploadIcon } from "../../../assets/upload.svg"
import { ReactComponent as FileUploadedIcon } from "../../../assets/icon-file-upload.svg"
import { ReactComponent as SuccessUpload } from "../../../assets/success-password.svg"
import { useCreateStudy } from "../../../hooks/studies"
import { useState } from "react"

interface UploadFilesModalProps {
  show: boolean
  toggle: () => void | undefined
}

export default function UploadFilesModal({
  show,
  toggle,
}: UploadFilesModalProps) {
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    multiple: false,
  })
  const [uploadFile, setUploadFile] = useState(false)

  const files = acceptedFiles.map((file) => (
    <div className={"name-file-upload"} key={file.name}>
      <FileUploadedIcon className="me-1" /> {file.name}
    </div>
  ))

  let fm = new FormData()

  acceptedFiles.map((file) => fm.append("file", file, file.name))

  const createStudy = useCreateStudy()

  const { onClick: dzOnClick, ...dzProps } = getRootProps()

  return (
    <Modal size="sm" centered show={show} onHide={toggle} data-test="modal-add_data">
      {files.length === 0 && (
        <Modal.Header className={"ModalHeader"}>
          <Modal.Title className={"ModalTitle"}>
            {files.length === 0 && "Upload new data"}
          </Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body className={"ModalBodyNoPadding"}>
        {!uploadFile ? (
          <>
            {" "}
            {files.length === 0 && (
              <div className={"TextModal"}>We support .zip and .xlsx file</div>
            )}
            <div className="dropzone" {...dzProps}>
              <input className="input-zone" {...getInputProps()} />
              <div className="text-center h-100 w-100">
                {files.length > 0 ? (
                  <div className="h-100 w-100 d-flex flex-column align-items-center justify-content-center">
                    {files}
                  </div>
                ) : (
                  <>
                    <UploadIcon />
                    <p className="dropzone-content mt-5">
                      <span onClick={dzOnClick} className="hover-word">
                        Click to upload
                      </span>{" "}
                      or drag and drop
                    </p>
                    <div className="max-size-upload">
                      Maximum file size 100MB
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <div className="pt-5 d-flex justify-content-center align-items-center flex-column">
            <SuccessUpload />
            <div className={"upload-ok"}>Data uploaded successfully!</div>
          </div>
        )}
      </Modal.Body>
      {files.length > 0 && !uploadFile ? (
        <Modal.Footer className={"ModalFooterNoPadding"}>
          <Button variant="outline-secondary" onClick={toggle}>
            Discard
          </Button>
          <Button
            variant="secondary"
            className="text-white"
            type="submit"
            onClick={async () => {
              await createStudy.mutateAsync(fm)
              setUploadFile(true)
            }}
          >
            Upload
          </Button>
        </Modal.Footer>
      ) : uploadFile ? (
        <Modal.Footer
          className={"ModalFooterNoPadding d-flex justify-content-center"}
        >
          <Button
            variant="secondary"
            className="text-white"
            type="submit"
            onClick={() => {
              toggle()
            }}
          >
            OK
          </Button>
        </Modal.Footer>
      ) : (
        <Modal.Footer className={"ModalFooterNoPadding"} />
      )}
    </Modal>
  )
}
