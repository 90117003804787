import { AxiosError } from "axios"

const naiveIsObj = (a: any) =>
  typeof a === "object" && a !== null && !Array.isArray(a)

const arrayze = (a: any) => (Array.isArray(a) ? a : [a])

const naiveIsArrayOfStrs = (a: any) =>
  Array.isArray(a) ? (a.length > 0 ? typeof a[0] === "string" : false) : false

export const transformErrorForForm = (
  error: AxiosError,
  skipTransform: boolean | string[] = false
) => {
  if (error.response?.status === 400) {
    let errorData
    if (naiveIsObj(error?.response?.data)) {
      errorData = error.response.data as Record<string, any>
    }

    const makeErrors = (errorObj: Record<string, any>): Record<string, any> => {
      return Object.keys(errorObj).reduce((transformed, key) => {
        const rawError = errorObj[key]
        let error
        if (
          skipTransform !== false &&
          (skipTransform === true ||
            (Array.isArray(skipTransform) && skipTransform.includes(key)))
        ) {
          return {
            ...transformed,
            [key]: rawError,
          }
        }
        if (naiveIsObj(rawError)) {
          error = makeErrors(rawError)
        } else if (naiveIsArrayOfStrs(rawError)) {
          error = arrayze(errorObj[key]).join(" ")
        } else if (Array.isArray(rawError)) {
          error = rawError.map((err) => makeErrors(err))
        } else {
          error = arrayze(errorObj[key]).join(" ")
        }
        return {
          ...transformed,
          [key]: error,
        }
      }, {})
    }

    if (errorData) {
      return makeErrors(errorData)
    }
  }
  // When no 400 (not related 2 form)
  // add a special __noFieldsServerError key \w original error
  return {
    __noFieldsServerError: error,
  }
}

export function convertHtmlToPlainText(html: string) {
  const parser = new DOMParser()
  return parser.parseFromString(html, "text/html").body.innerText.trim()
}
