// import * as Sentry from '@sentry/react'
import EazyAuth from 'use-eazy-auth'
import axios from 'axios'

const loginCall = (params: Record<string, string>) =>
  axios.post('/api/v1/token/', params).then((r) => ({
    accessToken: r.data.access,
    refreshToken: r.data.refresh,
  }))

const meCall = (token: string) =>
  axios
    .get('/api/v1/me/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((r) => r.data)

const refreshTokenCall = (refreshToken: string) =>
  axios
    .post('/api/v1/token/refresh/', {
      refresh: refreshToken,
    })
    .then((r) => ({
      accessToken: r.data.access,
      refreshToken: refreshToken,
    }))

function Auth({ children }: { children: JSX.Element }) {
  return (
    <EazyAuth
      onAuthenticate={(user) => {
        if (process.env.REACT_APP_SENTRY_RELEASE) {
          // Sentry.setUser({
          //   id: user.id,
          //   email: user.email,
          // })
        }
      }}
      onLogout={() => {
        if (process.env.REACT_APP_SENTRY_RELEASE) {
          // Sentry.setUser(null)
        }
      }}
      loginCall={loginCall}
      meCall={meCall}
      refreshTokenCall={refreshTokenCall}
    >
      {children}
    </EazyAuth>
  )
}

export default Auth