import { Suspense } from "react"
import { Spinner } from "react-bootstrap"
import { Outlet } from "react-router-dom"
import { useAuthUser } from "use-eazy-auth"
import { useIsSmallScreen } from "../hooks/screen"
import { AccountUser } from "../types"
import SmallDevicePlaceholder from "./SmallDevicePlaceholder/SmallDevicePlaceholder"
import TopBar from "./TopBar/TopBar"
import { UploadWatch } from "./UploadWatch"

export default function Layout() {
  const isSmallScreen = useIsSmallScreen()
  const { user } = useAuthUser<AccountUser>()
  return (
    <>
      {isSmallScreen ? (
        <Suspense
          fallback={
            <div className="h-100 w-100 padding-topbar d-flex align-items-center justify-content-center">
              <Spinner />
            </div>
          }
        >
          <SmallDevicePlaceholder />
          {user?.permission_editor && <UploadWatch />}
        </Suspense>
      ) : (
        <>
          <TopBar showMenu />
          <Suspense
            fallback={
              <div className="h-100 w-100 padding-topbar d-flex align-items-center justify-content-center">
                <Spinner />
              </div>
            }
          >
            <Outlet />
          </Suspense>
          {user?.permission_editor && <UploadWatch />}
        </>
      )}
    </>
  )
}
