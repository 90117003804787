import { Field, Formik } from "formik"
import { Badge, Button, Modal } from "react-bootstrap"
import { AccountUser, BaseAccountUser } from "../../../types"
import * as Yup from "yup"
import { InputField, transformErrorForForm } from "../../form"
import styles from "./AccountModal.module.css"

interface AccountModalProps {
  show: boolean
  toggle?: () => void | undefined
  account?: AccountUser
  save: (account: BaseAccountUser) => Promise<unknown>
}

const AccountSchema = Yup.object().shape({
  full_name: Yup.string().required(),
  email: Yup.string().email().required(),
})

const initialValues: BaseAccountUser = {
  full_name: "",
  email: "",
  is_active: true,
  permission_accounts: false,
  permission_editor: false,
  permission_viewer: false,
}

export default function AccountModal({
  show,
  toggle,
  account,
  save,
}: AccountModalProps) {
  return (
    <Modal size="sm" centered show={show} onHide={toggle} data-test="modal-account">
      <Formik
        validationSchema={AccountSchema}
        onSubmit={(account, { setErrors }) =>
          save(account).catch((err) => {
            setErrors(transformErrorForForm(err))
          })
        }
        validateOnMount
        initialValues={(account ?? initialValues) as BaseAccountUser}
      >
        {({ handleSubmit, isSubmitting, setFieldValue, values, isValid }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Header className={"ModalHeader"}>
              <Modal.Title className={"ModalTitle"}>
                {account
                  ? `Edit ${account.full_name} profile`
                  : "Add a new user profile"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className={"ModalBody"}>
              <div>
                <Field
                  label="Name"
                  placeholder="Enter name"
                  name="full_name"
                  component={InputField}
                />
              </div>
              <div className="mt-3">
                <Field
                  label="Email"
                  placeholder="Enter e-mail"
                  name="email"
                  component={InputField}
                />
              </div>
              <div className="mt-4">
                <div className={styles.LabelPermissions}>
                  Select one or more permissions:
                </div>
                <div className="mt-2">
                  <Badge
                    bsPrefix={
                      values.permission_viewer ? "pill" : "pill-outline-primary"
                    }
                    className={"pointer"}
                    pill
                    onClick={() =>
                      setFieldValue(
                        "permission_viewer",
                        !values.permission_viewer
                      )
                    }
                    bg={values.permission_viewer ? "primary" : "white"}
                  >
                    Viewer
                  </Badge>
                  <Badge
                    bsPrefix={
                      values.permission_editor ? "pill" : "pill-outline-primary"
                    }
                    className={"pointer ms-2"}
                    pill
                    onClick={() =>
                      setFieldValue(
                        "permission_editor",
                        !values.permission_editor
                      )
                    }
                    bg={values.permission_editor ? "primary" : "white"}
                  >
                    Data editor
                  </Badge>
                  <Badge
                    bsPrefix={
                      values.permission_accounts ? "pill" : "pill-outline-primary"
                    }
                    className={"pointer ms-2"}
                    pill
                    onClick={() =>
                      setFieldValue(
                        "permission_accounts",
                        !values.permission_accounts
                      )
                    }
                    bg={values.permission_accounts ? "primary" : "white"}
                  >
                    Account editor
                  </Badge>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className={"ModalFooter"}>
              <Button variant="outline-secondary" onClick={toggle}>
                Close
              </Button>
              <Button
                variant="secondary"
                className="text-white"
                type="submit"
                disabled={isSubmitting || !isValid}
                // onClick={}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </form>
        )}
      </Formik>
    </Modal>
  )
}
