import { ReactComponent as Matching } from "../../../assets/matching.svg"
import { ReactComponent as Unmatching } from "../../../assets/unmatching.svg"

interface MatchUnmatchProps {
  mz: number
  mz_min: number
  mz_max: number
  masses: string
}

export const amountFormatter = new Intl.NumberFormat("it-IT", {
  maximumFractionDigits: 10,

})

export default function MatchUnmatchMasses({
  mz,
  mz_max,
  mz_min,
  masses,
}: MatchUnmatchProps) {
  if (mz >= mz_min && mz <= mz_max) {
    return (
      <div
        className={masses === "all" ? "matching-class" : "matching-class-no-bg"}
      >
        {mz_min !== 0 && mz_min !== 0 && <Matching className="me-1" />} {amountFormatter.format(mz)}
      </div>
    )
  } else {
    return (
      <div className="unmatching-class">
        {mz_min !== 0 && mz_min !== 0 && <Unmatching className="me-1" />} {amountFormatter.format(mz)}
      </div>
    )
  }
}
