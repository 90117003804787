import {
  useCancelAllUploads,
  useCancelUpload,
  useUploadJobs,
} from "../../hooks/uploadJobs"
import { ReactComponent as FileIcon } from "../../assets/icons/file.svg"
import { ReactComponent as CloseUpload } from "../../assets/icons/close-upload.svg"
import { ReactComponent as ReduceUpload } from "../../assets/icons/reduce-upload.svg"
import { ReactComponent as OpenUpload } from "../../assets/icons/open-upload.svg"
import { ReactComponent as CancelUploadIcon } from "../../assets/cancel-upload.svg"
import S from "./UploadWatch.module.css"
import { Spinner } from "react-bootstrap"
import { useState } from "react"

export function UploadWatch() {
  const { data } = useUploadJobs()
  const cancelUpload = useCancelUpload()
  const cancelAllUploads = useCancelAllUploads()
  const [isReduced, setReduced] = useState(false)
  const [hoverUpload, setHoverUpload] = useState<number | null>()

  if ((data?.length ?? 0) === 0) {
    // Se è vuoto non mostriamo la tray
    return null
  }

  return (
    <div id={S["upload-tray"]}>
      <div className={S.header}>
        <div>Uploading items</div>
        <div className="d-flex align-items-center">
          {!isReduced ? (
            <ReduceUpload
              className="pointer me-2"
              onClick={() => setReduced(!isReduced)}
            />
          ) : (
            <OpenUpload
              className="pointer me-2"
              onClick={() => setReduced(!isReduced)}
            />
          )}
          <CloseUpload
            onClick={() => {
              cancelAllUploads.mutateAsync()
            }}
            className="pointer"
          />
        </div>
      </div>
      {!isReduced && (
        <div className={S.body}>
          {data?.map((upload) => {
            return (
              <div className="mb-3" key={upload.id}>
                <div className="w-100 d-flex justify-content-between align-items-center">
                  <FileIcon style={{ flex: "0 0 24px"}} />
                  <span className={S.NameFileUpload}>{upload.name}</span>
                  <div>
                    <span>
                      {upload.status === "pending" && (
                        <>
                          <div
                            onMouseEnter={() => setHoverUpload(upload.id)}
                            onMouseLeave={() => setHoverUpload(null)}
                          >
                            {hoverUpload === upload.id ? (
                              <CancelUploadIcon
                                width={17}
                                height={17}
                                className="pointer"
                                onClick={() => {
                                  cancelUpload.mutateAsync(upload.id)
                                }}
                              />
                            ) : (
                              <Spinner size="sm" variant="secondary" />
                            )}
                          </div>
                        </>
                      )}
                    </span>
                    <span>{upload.status === "success" && "OK"}</span>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
