import AccountModal from "../../components/AccountManagement/AccountModal/AccountModal"
import {
  useAccountUsers,
  useCreateAccountUser,
  useDeleteAccountUser,
  useReSendUserActivationLink,
  useUpdateAccountUser,
} from "../../hooks/accounts"
import { useQsFilters } from "../../hooks/filters"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import styles from "./AccountManagement.module.css"
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg"
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete.svg"
import { ReactComponent as SendIcon } from "../../assets/icons/send.svg"
import { ReactComponent as NotFound } from "../../assets/not-found.svg"
import { Badge, Form } from "react-bootstrap"
import dayjs from "dayjs"
import ConfirmDeleteAccountModal from "../../components/AccountManagement/ConfirmDeleteAccountModal/ConfirmDeleteAccountModal"
import StickyTable from "../../components/StickyTable"
import ConfirmSwitchAccountModal from "../../components/AccountManagement/ConfirmSwitchAccountModal/ConfirmSwitchAccountModal"
import ConfirmResendEmailModal from "../../components/AccountManagement/ConfirmResendEmailModal/ConfirmResendEmailModal"
import Paginator from "../../components/Paginator"

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get("page") ?? 1),
  search: params.get("search") ?? "",
})

export default function AccountManagement() {
  const { filters, uiFilters, setFilters, setFiltersDebounced } =
    useQsFilters(initFilters)
  const { data } = useAccountUsers(filters)
  const createAccount = useCreateAccountUser()
  const updateAccount = useUpdateAccountUser()
  const deleteAccount = useDeleteAccountUser()
  const resendEmailAccount = useReSendUserActivationLink()
  const [modalCreateAccount, modalCreateAccountActions] = useModalTrigger()
  const [modalEditAccount, modalEditAccountActions] = useModalTrigger()
  const [modalDeleteAccount, modalDeleteAccountActions] = useModalTrigger()
  const [modalDeactivateAccount, modalDeactivateAccountActions] =
    useModalTrigger()
  const [modalResendEmailAccount, modalResendEmailAccountActions] =
    useModalTrigger()
  return (
    <div className="w-100 h-100">
      <div className={styles.AccountManagement}>
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <h2 className={styles.Title}>Account management</h2>
            <button
              onClick={() => modalCreateAccountActions.open()}
              className="btn btn-secondary text-white"
              data-test="add"
            >
              Add a new account
            </button>
          </div>
          <div className="mt-4 d-flex justify-content-between align-items-center w-100">
            <input
              placeholder="Search"
              className={styles.SearchInput}
              type="text"
              value={uiFilters.search}
              onChange={(e) => setFiltersDebounced({ search: e.target.value })}
            />
            <div>
              <div>
                <span className={styles.NumberResults}>
                  {data?.count}/{data?.full_count}
                </span>
              </div>
            </div>
          </div>
        </div>
        {(data?.results?.length ?? 0) > 0 ? (
          <StickyTable className="flex-1 mt-5" data-test="table">
            <thead>
              <tr>
                <th className="border-radius-left-20">Name</th>
                <th>Contact</th>
                <th>Permissions</th>
                <th>State</th>
                <th>Activation date</th>
                <th className="border-radius-right-20"></th>
              </tr>
            </thead>
            <tbody>
              {data?.results.map((user) => (
                <tr key={user.id}>
                  <td>{user.full_name}</td>
                  <td>{user.email}</td>
                  <td>
                    {user.permission_viewer && (
                      <Badge
                        bsPrefix={"pill-outline-primary-no-hover"}
                        className={"me-2"}
                        pill
                        bg={"white"}
                      >
                        Viewer
                      </Badge>
                    )}
                    {user.permission_editor && (
                      <Badge
                        bsPrefix={"pill-outline-primary-no-hover"}
                        className={"me-2"}
                        pill
                        bg={"white"}
                      >
                        Data editor
                      </Badge>
                    )}
                    {user.permission_accounts && (
                      <Badge
                        bsPrefix={"pill-outline-primary-no-hover"}
                        className={"me-2"}
                        pill
                        bg={"white"}
                      >
                        Account editor
                      </Badge>
                    )}
                  </td>
                  <td>
                    <Form.Check
                      checked={user.is_active}
                      type="switch"
                      onChange={() => modalDeactivateAccountActions.open(user)}
                      label={user.is_active ? "Active" : "Disactive"}
                      id="disabled-custom-switch"
                      data-test="switch-state"
                    />
                  </td>
                  <td>
                    {user.date_joined
                      ? dayjs(user.date_joined).format("DD-MM-YYYY")
                      : ""}
                  </td>
                  <td>
                    <div className="d-flex">
                      <div className="icon-hover">
                        <EditIcon
                          className="pointer"
                          title="Update Account"
                          onClick={() => modalEditAccountActions.open(user)}
                          data-test="edit"
                        />
                      </div>
                      <div className="icon-hover  ms-3">
                        <SendIcon
                          onClick={() =>
                            modalResendEmailAccountActions.open(user)
                          }
                          className="pointer"
                          data-test="send-email"
                        />
                      </div>
                      <div className="icon-hover  ms-3">
                        <DeleteIcon
                          onClick={() => modalDeleteAccountActions.open(user)}
                          className="pointer"
                          data-test="delete"
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </StickyTable>
        ) : (
          <div className="d-flex pt-5 flex-column align-items-center justify-content-center">
            <NotFound />
            <div className={styles.NotFound}>No results found</div>
            <div className={styles.InteractPanel}>
              Interact with the filter panel to discover the data
            </div>
          </div>
        )}
        {(data?.results.length ?? 0) > 0 && (
          <Paginator
            count={data?.count ?? 0}
            currentPage={uiFilters.page}
            goToPage={(page) => setFilters({ page })}
          />
        )}
      </div>
      <AccountModal
        show={modalCreateAccount.isOpen}
        toggle={modalCreateAccountActions.toggle}
        save={async (account) => {
          await createAccount.mutateAsync(account)
          modalCreateAccountActions.close()
        }}
      />
      <AccountModal
        show={modalEditAccount.isOpen}
        toggle={modalEditAccountActions.toggle}
        save={async (account) => {
          await updateAccount.mutateAsync(account)
          modalEditAccountActions.close()
        }}
        account={modalEditAccount.value}
      />
      <ConfirmDeleteAccountModal
        show={modalDeleteAccount.isOpen}
        toggle={modalDeleteAccountActions.toggle}
        onConfirm={async () => {
          await deleteAccount.mutateAsync(modalDeleteAccount.value!.id)
          modalDeleteAccountActions.close()
        }}
        user={modalDeleteAccount.value}
      />
      <ConfirmSwitchAccountModal
        show={modalDeactivateAccount.isOpen}
        toggle={modalDeactivateAccountActions.toggle}
        onConfirm={async () => {
          await updateAccount.mutateAsync({
            ...modalDeactivateAccount.value,
            is_active: !modalDeactivateAccount.value.is_active,
          })
          modalDeactivateAccountActions.close()
        }}
        user={modalDeactivateAccount.value}
      />
      <ConfirmResendEmailModal
        show={modalResendEmailAccount.isOpen}
        toggle={modalResendEmailAccountActions.toggle}
        onConfirm={async () => {
          await resendEmailAccount.mutateAsync(modalResendEmailAccount.value.id)
          modalResendEmailAccountActions.close()
        }}
        user={modalResendEmailAccount.value}
      />
    </div>
  )
}
