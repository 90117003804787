import { createElement, useMemo, ComponentType } from "react"
import { Navigate, useLocation, To } from "react-router"
import { useAuthState, useAuthUser } from "use-eazy-auth"

export interface RequireAuthProps {
  children: JSX.Element
  redirectTo?: To
  spinner?: JSX.Element
  spinnerComponent?: ComponentType
  rememberReferrer?: boolean
  redirectTest?: (user: any) => To | undefined
}

export function RequireAuth({
  children,
  redirectTest,
  redirectTo = "/login",
  spinner,
  spinnerComponent,
  rememberReferrer = true,
}: RequireAuthProps) {
  const { authenticated, bootstrappedAuth, loginLoading } = useAuthState()
  const { user } = useAuthUser()
  const location = useLocation()

  const userRedirectTo = useMemo(() => {
    if (user && typeof redirectTest === "function") {
      const userRedirectTo = redirectTest(user)
      if (userRedirectTo) {
        return userRedirectTo
      }
    }
    return null
  }, [user, redirectTest])

  if (!bootstrappedAuth || loginLoading) {
    // Spinner or Spinner Component
    return spinnerComponent ? createElement(spinnerComponent) : spinner ?? null
  }

  if (authenticated) {
    // Redirect a logged user?
    if (userRedirectTo) {
      return <Navigate to={userRedirectTo} />
    }
    return children
  }

  return (
    <Navigate
      to={redirectTo}
      state={
        rememberReferrer
          ? {
              referrer: location,
            }
          : undefined
      }
    />
  )
}

export interface RequireGuestProps {
  children: JSX.Element
  redirectTo?: To
  spinner?: JSX.Element
  spinnerComponent?: ComponentType
  redirectToReferrer?: boolean
}

export function RequireGuest({
  children,
  spinner,
  spinnerComponent,
  redirectTo = "/",
  redirectToReferrer = true,
}: RequireGuestProps) {
  const { authenticated, bootstrappedAuth } = useAuthState()
  const location = useLocation()

  if (authenticated) {
    const state = location.state as Record<string, any> | undefined
    // Redirect to referrer location
    if (redirectToReferrer && state && state.referrer) {
      const { referrer } = state
      const toReferrer = {
        pathname: referrer.pathname,
        search: referrer.search,
        hash: referrer.hash,
      }
      return <Navigate to={toReferrer} />
    }
    return <Navigate to={redirectTo} />
  }

  if (!bootstrappedAuth) {
    // Spinner as element as component or null
    return spinnerComponent ? createElement(spinnerComponent) : spinner ?? null
  }

  return children
}
